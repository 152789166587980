<template>
    <div id="vditor"></div>
</template>

<script>
    import { defineComponent } from 'vue';
    import VditorPreview from 'vditor/dist/method.min'
    import 'vditor/dist/index.css'
    import {ElMessage} from "element-plus";
    import useMarkdownLinkCopy from "@/composables/useMarkdownLinkCopy";
    import {CDN} from "@/config/cdn";

    export default defineComponent({
        name: "Show",
        props: ['content', 'anchor'],
        setup() {

            const {copyMarkdownLinkByFullPath} = useMarkdownLinkCopy();

            return {
                copyMarkdownLinkByFullPath
            }
        },
        mounted() {
            if (this.content && this.content !== '') {
                this.preview(this.content);
            }
        },
        watch: {
            content(value, old) {
                this.preview(value);
            }
        },
        methods: {
            preview(content) {
                const vm = this;
                const mdComponent = document.getElementById("vditor");
                VditorPreview.preview(
                    mdComponent,
                    content,
                    {
                        cdn: CDN,
                        theme: {
                            path: `${CDN}/dist/css/content-theme`
                        },
                        anchor: 1,
                        after: () => {
                            vm.scrollTo(vm.anchor)
                            vm.listenToClickAnchor()
                        }
                    }
                );
            },
            scrollTo(rawHash) {
                if (!rawHash) {
                    return
                }
                const navContainer = document.getElementById("header-nav-container");
                const navContainerHeight = parseInt(window.getComputedStyle(navContainer).height,10);
                const hash = this.getHashValue(rawHash);
                const scrollHeight = document.getElementById(hash).offsetTop - navContainerHeight;
                window.scroll(0,scrollHeight);
            },
            listenToClickAnchor() {
                const vm = this
                document.querySelectorAll("a").forEach((value => {
                    const href = value.getAttribute("href")
                    if (!href || !href.startsWith("#")) {
                        return;
                    }
                    value.addEventListener("click", function (e) {
                        const rawHash = e.currentTarget.hash;
                        if (!rawHash) {
                            return;
                        }
                        const decodeHash = decodeURI(rawHash);
                        const classList = e.currentTarget.classList;
                        if (classList && classList.contains('vditor-anchor')) {
                            if (vm.copyMarkdownLinkByFullPath({
                                fullPath: vm.$route.path + decodeHash,
                                name: vm.getHashValue(decodeHash),
                            })) {
                                ElMessage({
                                    showClose: true,
                                    message: '复制内部链接成功',
                                    type: 'success'
                                });
                            }
                        }
                        if(history.pushState) {
                            history.pushState(null, null, decodeHash);
                        } else {
                            location.hash = decodeHash;
                        }
                        vm.scrollTo(decodeHash)
                        e.preventDefault();
                    });
                    // value.addEventListener("dblclick", function (e) {
                    //     const rawHash = e.currentTarget.hash;
                    //     if (!rawHash) {
                    //         return;
                    //     }
                    //     const classList = e.currentTarget.classList;
                    //     if (classList && classList.contains('vditor-anchor')) {
                    //         const decodeHash = decodeURI(rawHash);
                    //         if (vm.copyMarkdownLinkByFullPath({
                    //             fullPath: vm.$route.path + decodeHash,
                    //             name: vm.getHashValue(decodeHash),
                    //         })) {
                    //             ElMessage({
                    //                 showClose: true,
                    //                 message: '复制内部链接成功'
                    //             });
                    //         }
                    //     }
                    //     e.preventDefault();
                    // });
                }));
            },
            getHashValue(rawHash) {
                return rawHash.startsWith("#") ? rawHash.substring(1) : rawHash;
            }
        }
    })
</script>

<style scoped lang="less">
    #vditor {
        padding-right: 20px;
        text-align: justify;
    }
</style>